import { Box, CircularProgress } from '@mui/material'
import React from 'react'

import { InspectionPaymentsPageProps } from './inspectionPaymentsPageTypes'
import useInspectionPaymentsPage from './useInspectionPaymentsPage'
import ErrorCard from '../../SalesforceOfferPageComponents/ErrorCard'

import InspectionPaymentsPageMobile from './InspectionPaymentsPageMobile'
import InspectionPaymentsPageTablet from './InspectionPaymentsPageTablet'
import InspectionPaymentsPageDesktop from './InspectionPaymentsPageDesktop'

const InspectionPaymentsPage = (props: InspectionPaymentsPageProps) => {
  const { accountId } = props

  const { isMobile, isTablet, error, loading, ...presentationalProps } =
    useInspectionPaymentsPage({ accountId })

  if (error) {
    return (
      <Box
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: 'trustBlue.main', overflow: 'hidden' }}
      >
        <ErrorCard text="Please try refreshing the page or contact us." />
      </Box>
    )
  }
  if (loading) {
    return (
      <Box height="auto" pt="50px" sx={{ backgroundColor: 'trustBlue.main' }}>
        <Box
          width="100%"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      </Box>
    )
  }
  if (isMobile) {
    return <InspectionPaymentsPageMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <InspectionPaymentsPageTablet {...presentationalProps} />
  }
  return <InspectionPaymentsPageDesktop {...presentationalProps} />
}

export default InspectionPaymentsPage
