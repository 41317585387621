import React from 'react'
import { Box, Typography, Button } from '@mui/material'

import { InspectionPaymentsPageViewProps } from './inspectionPaymentsPageTypes'

const InspectionPaymentsPageDesktop = (
  props: InspectionPaymentsPageViewProps
) => {
  const {
    completedInspectionPayments,
    notCompletedInspectionPayments,
    notCompletedInspectionPaymentsOnclick,
  } = props
  return (
    <Box display="flex" flexDirection="column" gap="48px" margin="48px">
      <Box>
        <Box>
          <Typography>Completed Inspection Payments</Typography>
        </Box>
        <Box>
          {completedInspectionPayments?.map((payment) => (
            <Box
              key={payment.id}
              display="flex"
              flexDirection="row"
              gap="16px"
              height="48px"
            >
              <Box>
                <Typography>{payment.amount}</Typography>
              </Box>
              <Box>
                <Typography>{payment.description}</Typography>
              </Box>
              <Button
                onClick={notCompletedInspectionPaymentsOnclick(payment.id)}
                size="small"
              >
                Go to Payment
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>Not Completed Inspection Payments</Box>
          <Box>{notCompletedInspectionPayments?.length}</Box>
        </Box>
        <Box>
          {notCompletedInspectionPayments?.map((payment) => (
            <Box
              key={payment.id}
              display="flex"
              flexDirection="row"
              gap="16px"
              height="48px"
            >
              <Box>
                <Typography>{payment.amount}</Typography>
              </Box>
              <Box>
                <Typography>{payment.description}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default InspectionPaymentsPageDesktop
