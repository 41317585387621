import { gql, useQuery } from '@apollo/client'
import { LandingGetInspectionPaymentsDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMediaQuery, useTheme } from '@mui/material'
import { navigate } from 'gatsby'

// import { Core_GetSalesforceInspectionPaymentsRequestInput } from '@flock/flock-gql-server/src/__generated__/graphql'
import { InspectionPaymentsPageProps } from './inspectionPaymentsPageTypes'

export const GET_INSPECTION_PAYMENTS = gql`
  query LandingGetInspectionPayments(
    $input: Core_GetSalesforceInspectionPaymentsRequestInput!
  ) {
    getSalesforceInspectionPayments(input: $input) {
      inspectionPayments {
        amount
        description
        id
        status
      }
    }
  }
`

const useInspectionPaymentsPage = (props: InspectionPaymentsPageProps) => {
  const { accountId } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const { data, loading, error } = useQuery(
    LandingGetInspectionPaymentsDocument,
    {
      variables: {
        input: {
          accountId,
        },
      },
    }
  )

  const notCompletedInspectionPaymentsOnclick = (paymentId: string) => () =>
    navigate(`/${accountId}/inspection-payments/${paymentId}`)

  const completedInspectionPayments =
    data?.getSalesforceInspectionPayments?.inspectionPayments.filter(
      (payment) => payment.status === 'Complete'
    ) || []

  const notCompletedInspectionPayments =
    data?.getSalesforceInspectionPayments?.inspectionPayments.filter(
      (payment) => payment.status === 'Not Complete'
    ) || []
  return {
    completedInspectionPayments,
    notCompletedInspectionPayments,
    loading,
    error,
    isMobile,
    isTablet,
    notCompletedInspectionPaymentsOnclick,
  }
}

export default useInspectionPaymentsPage
